import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import SidebarComponent from './Components/sidebar';
import ViewArea from './Views/ViewArea';
import Signin from './Views/login';
import Landing from './Views/landing';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPanelOpen: false,
    };
    this.onPanelexpand = this.onPanelexpand.bind(this);
  }

  onPanelexpand(isPanelOpen) {
    this.setState({ isPanelOpen });
  }

  render() {
    const { isPanelOpen } = this.state;
    const { location } = this.props;
    console.log('location.pathname', location.pathname);
  
    // Redirect if not logged in and not on home or login page
    if (['/home', '/login'].indexOf(location.pathname) === -1 && !localStorage.getItem('userdetails')) {
      return <Redirect push to="/home" />;
    }
  
    return (
      <div className="app-container">
        {location.pathname === '/home' && <Route path="/home" component={Landing} />}
        {location.pathname === '/login' && <Route path="/login" component={Signin} />}
        {['/home', '/login'].indexOf(location.pathname) === -1 && (
          <>
            <SidebarComponent onPanelexpand={this.onPanelexpand} />
            <ViewArea isPanelOpen={isPanelOpen} />
            <ToastContainer
              className="toast-container"
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              closeButton={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </div>
    );
  }
  
}

export default withRouter(App);
