import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DropDown(props) {
  const classes = useStyles();
  const {
    selectedValue,
    dropDownOptions,
    label,
    handleOptionChange,
  } = props;
  const [dropDownValue, setDropDownValue] = useState(selectedValue);
  useEffect(() => {
    setDropDownValue(selectedValue)
  }, [selectedValue])
  
  const handleChange = (event) => {
    setDropDownValue(event.target.value);
    handleOptionChange(event);
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select value={String(dropDownValue)} onChange={handleChange} label={label}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {dropDownOptions &&
          dropDownOptions.map((option) => {
            const optionVal =
              typeof option === "object"
                ? option
                : { value: option, label: option };
            return (
              <MenuItem value={String(optionVal.value)}>{optionVal.label}</MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
