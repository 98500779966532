import React from 'react';
import Icon from '../icon';
import './main.scss';

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
    };
  }

  componentDidMount() {
    const userDetail = JSON.parse(localStorage.getItem('userdetails'));
    const { userData } = userDetail;
    this.setState({ userData });
  }

  getTableBodyElement() {
    const { onRowSelect, tableDatas, onChangeUserStatus } = this.props;
    const { userData } = this.state;
    return tableDatas.map((tableData) => {
      const tabelDataArray = [
        tableData.first_name,
        tableData.designation,
        tableData.division,
        tableData.qualification,
        tableData.contact_number,
        tableData.subscription ? 'Auto' : '',
      ];
      return (
        <tr>
          {tabelDataArray.map((data) => (
            <td onClick={() => onRowSelect(tableData.id)}>{data}</td>
          ))}
          {(userData.user_type === 'super_admin'
            || userData.user_type === 'admin') && (
            <td>
              <div className="d-flex">
                {tableData.admin_approve_status !== 'active' && (
                <button
                  className="btn primary"
                  onClick={() => onChangeUserStatus(tableData.id, 'active')}
                >
                  Approve
                </button>
                )}
                {tableData.admin_approve_status !== 'rejected' && (
                <button
                  className="btn primary"
                  onClick={() => onChangeUserStatus(tableData.id, 'rejected')}
                >
                  Reject
                </button>
                )}
              </div>
            </td>
          )}
        </tr>
      );
    });
  }

  render() {
    const { userData } = this.state;
    return (
      <table className="Data-Table">
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>FIRST NAME</th>
            <th>DESIGNATION</th>
            <th>DIVISION</th>
            <th>QUALIFICATION</th>
            <th>CONTACT NUMBER</th>
            <th>SUBSCRIPTION</th>
            {(userData && (userData.user_type === 'super_admin'
              || userData.user_type === 'admin')) && <th />}
          </tr>
        </thead>
        <tbody>{this.getTableBodyElement()}</tbody>
      </table>
    );
  }
}

export default DataTable;
