class APIS {
  constructor(_endpoint) {
    const domain = 'https://tnebtpas-server.onrender.com';
    const prefix = '/api';
    const endpoint = _endpoint || (domain + prefix);
    const urls = {
      SIGN_IN: () => '/login',
      GET_USERS: () => '/user',
      GET_USERDETAILS_LIST: () => '/user_detail',
      GET_FILTERED_USERDETAILS_LIST: () => '/user_detail/filter',
      GET_USERDETAILS: (user_id) => `/user_detail/${user_id}`,
      ADD_USER: () => `/user`,
      UPDATE_USER_DETAILS: (user_id) => `/user_detail/${user_id}`,
      ADD_WELFARE: (userId) => `/welfare/${userId}`,
      GET_WELFARE: (userId) => `/welfare/${userId}`,
      CREATE_ID_CARD: (userId) => `/create-membership-id/${userId}`,
    };

    this.domain = _endpoint ? '' : domain;
    this.prefix = _endpoint ? '' : prefix;
    this.endpoint = endpoint;
    this.urls = urls;
    this.get_url = this.get_url.bind(this);
  }

  get_url(name, ...data) {
    const url = this.urls[name](...data);
    return `${this.endpoint}${url}`;
  }
}

const Apis = new APIS();
export { Apis as APIS };
