export const districts = [
  'ARIYALUR',
  'CHENGALPATTU',
  'CHENNAI',
  'COIMBATORE',
  'CUDDALORE',
  'DHARMAPURI',
  'DINDIGUL',
  'ERODE',
  'KALLAKURICHI',
  'KANCHIPURAM',
  'KANNIYAKUMARI',
  'KARUR',
  'KRISHNAGIRI',
  'MADURAI',
  'MAYILADUTHURAI',
  'NAGAPATTINAM',
  'NAMAKKAL',
  'NILGIRIS',
  'PERAMBALUR',
  'PUDUKKOTTAI',
  'RAMANATHAPURAM',
  'RANIPET',
  'SALEM',
  'SIVAGANGAI',
  'TENKASI',
  'THANJAVUR',
  'THENI',
  'THOOTHUKUDI',
  'TIRUCHIRAPPALLI',
  'TIRUNELVELI',
  'TIRUPATTUR',
  'TIRUPPUR',
  'TIRUVALLUR',
  'TIRUVANNAMALAI',
  'TIRUVARUR',
  'VELLORE',
  'VILUPPURAM',
  'VIRUDHUNAGAR',
];
