/* eslint-disable react/no-deprecated */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Icon from '../icon';
import './main.scss';


class SidebarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarOpen: false,
      curretRoute: '',
    };
    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({ curretRoute: location.pathname });
  }

  componentWillReceiveProps(newProps) {
    const { location } = this.props;
    const newLocation = newProps.location;
    if (newLocation !== location) {
      this.setState({ curretRoute: newLocation.pathname });
    }
  }

  toggleSideBar(sideBarOpen) {
    const { onPanelexpand } = this.props;
    this.setState({ sideBarOpen });
    onPanelexpand(sideBarOpen);
  }

  render() {
    const { sideBarOpen, curretRoute } = this.state;
    return (
      <div className="sidebar" style={{ width: sideBarOpen ? '185px' : '60px' }}>
        <div className="header">
          {/* <Icon name="settings" color="selected" className="customized-sidebar-icon" /> */}
          <div className="toggle-side-menu">
            <Icon name="menu" onClick={() => this.toggleSideBar(!sideBarOpen)} className="customized-sidebar-icon" />
          </div>
        </div>
        <div className="side-menu-holder">
          <Link to="/dashboard">
            <div className={`side-menu ${curretRoute === '/dashboard' ? 'selected' : ''}`}>
              <Icon name="dashboard" className="customized-sidebar-icon" />
              <span className="menu-item-header">{sideBarOpen ? 'Dahboard' : ''}</span>
            </div>
          </Link>
          <Link to="/add-user">
            <div className={`side-menu ${curretRoute === '/add-user' ? 'selected' : ''}`}>
              <Icon name="user" className="customized-sidebar-icon" />
              <span className="menu-item-header">{sideBarOpen ? 'Add User' : ''}</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(SidebarComponent);
