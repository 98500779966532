import React from 'react';
import Navbar from '../Components/navbar';
import { withRouter } from 'react-router-dom';

// import Dashboard from './dashboard';
import Routes from '../routes';

import '../Scss/ViewArea.scss';

class ViewArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { isPanelOpen } = this.props;
    return (
      <div className="ViewArea" style={{ width: isPanelOpen ? 'calc(100% - 185px)' : 'calc(100% - 60px)' }}>
        <Navbar />
        <div className="route-area">
          <Routes />
        </div>
      </div>
    );
  }
}

export default withRouter(ViewArea);
