import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Dashboard from './Views/dashboard';
import UserDetail from './Views/userdetail';
import AddUser from './Views/add-user';
import Landing from './Views/landing';


const routes = () => (
  <Switch>
    <Route exact path="/user-detail/:userId" component={UserDetail} />
    <Route path="/add-user/:welfare/:userId" component={AddUser} />
    <Route path="/add-user/:userId" component={AddUser} />
    <Route path="/add-user" component={AddUser} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/home" component={Landing} />
    <Redirect from="/" to="/home" />
  </Switch>
);

export default routes;
